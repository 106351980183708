const routes = [
  {
    path: "/",
    redirect: "/sign_in",
  },
  {
    path: "/sign_in",
    name: "Login",
    component: () => import("../../views/public/login"),
    meta: {
      layout: () => import("../../layouts/public"),
    },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("../../views/public/privacyPolicy.vue"),
    meta: {
      layout: () => import("../../layouts/public"),
    },
  },
  {
    path: "/delete-account",
    name: "DeleteAccount",
    component: () => import("../../views/public/deleteAccount.vue"),
    meta: {
      layout: () => import("../../layouts/public.vue"),
    },
  },
];

export default routes;

const state = {
  user: {},
};

const getters = {
  user(state) {
    return state.user;
  },
};

const mutations = {
  USER_DETAILS(state, data) {
    state.user = data;
  },
};

const actions = {
  async get_users(_, filter) {
    return window.axios.get("/users" + filter);
  },
  async get_summary(_, filter) {
    return window.axios.get("/users-summary" + filter);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

const state = {
  token: null,
  user: {},
};

const getters = {
  token(state) {
    return state.token;
  },
  user(state) {
    return state.user;
  },
};

const mutations = {
  SET_TOKEN(state, data) {
    state.token = data;
  },
  SET_USER(state, data) {
    state.user = data;
  },
};

const actions = {
  async login_user({ commit }, payload) {
    const response = await window.axios.post("/login", payload);
    commit("SET_TOKEN", response.data.token);
    commit("SET_USER", response.data.user);
    return response;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

const ls = new SecureLS({
  isCompression: false,
  encodingType: "aes",
  encryptionSecret: "HavilAdmin123$",
});
Vue.use(Vuex);

import auth from "./modules/auth";
import users from "./modules/users";
import transactions from "./modules/transactions";

export default new Vuex.Store({
  modules: {
    auth,
    users,
    transactions,
  },
  plugins: [
    createPersistedState({
      key: process.env.VUE_APP_NAME,
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});

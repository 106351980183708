const state = {};

const getters = {};

const mutations = {};

const actions = {
  async get_transactions(_, filter) {
    return window.axios.get("/transactions" + filter);
  },
  async get_summary(_, filter) {
    return window.axios.get("/transactions-summary" + filter);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

const routes = [
  {
    path: "/users",
    name: "Users",
    component: () => import("../../views/protected/users"),
    meta: {
      layout: () => import("../../layouts/authenticated"),
    },
  },
  {
    path: "/users/:id/details",
    name: "UserDetails",
    component: () => import("../../components/users/UserDetails"),
    meta: {
      layout: () => import("../../layouts/authenticated"),
    },
  },
];

export default routes;
